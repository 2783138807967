import React from "react";
const ApiReducer = (state, action) => {
  switch (action.type) {
    case "GET_AGREEMENTS":
      return {
        ...state,
        agreements: action.payload.data,
      };
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.payload.data.filter((activeProduct) => activeProduct.active === true),
      };

    default: {
      return state;
    }
  }
};

export default ApiReducer;
