import React, { useState, useContext } from "react";
import { Form, Button, Toast, Spinner } from "react-bootstrap";
import TermsPrivacy from "../Modals/TermsPrivacy";
import Metamaskicon from "../../assets/images/metamaskicon.png";
import { Formik } from "formik";
import { validationSchema } from "../../helpers/Validations";
import WalletContext from "../../store/Wallet/WalletContext";
import ExtensionPopup from "../Modals/ExtenstionPopup";
import ConnectAccount from "../Modals/ConnectAccount";
import ApiContext from "../../store/Apis/ApiContext";
import cloud from "../../assets/images/clouds.png";
import PDFFile from "../../assets/images/10mb.pdf";
const Forms = ({ pay, counter, tokenPerTon }) => {
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);

	const [showError, setShowError] = useState(true);

	const [terms, setTerms] = useState(false);

	const handleShow = (params) => {
		params === "term" ? setTerms(true) : setTerms(false);
		setShow(true);
	};

	const { connect, active, account, sendTrx } = useContext(WalletContext);
	const { products } = useContext(ApiContext);
	const validate = () => {
		if (typeof window.ethereum == "undefined") {
			setShow1(true);
		} else {
			connect();
		}
	};

	return (
		<>
			<Formik
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					// setSubmitting(true);
					// alert(JSON.stringify(values, null, 2));
					// alert(account);
					// console.log({ values });
					const timestamp = Date.now();
					// console.log({ timestamp });
					// resetForm();

					sendTrx(values, products, counter, timestamp);
					// values.terms1 = false;
					// values.terms2 = false;
				}}
				initialValues={{
					name: "",
					email: "",
					terms1: false,
					terms2: false,
				}}>
				{({ handleSubmit, handleChange, resetForm, values, errors }) => (
					<Form className="formstyle" noValidate onSubmit={handleSubmit}>
						<Form.Group className="d-flex align-items-center" controlId="name">
							<Form.Control
								className="input "
								type="text"
								isInvalid={!!errors.name}
								placeholder="name"
								name="name"
								onChange={handleChange}
							/>
							<label style={{ fontSize: "10px", marginLeft: "8px" }}>
								Optional
							</label>
						</Form.Group>
						<div className="text-danger">
							<p className="fs-12">{errors.name}</p>
						</div>
						<Form.Group className="d-flex align-items-center" controlId="email">
							<Form.Control
								className="input input-after"
								type="email"
								name="email"
								isInvalid={!!errors.email}
								onChange={handleChange}
								placeholder="email address"
							/>
							<label style={{ fontSize: "10px", marginLeft: "8px" }}>
								Optional
							</label>
						</Form.Group>
						<div className="text-danger">
							<p className="fs-12">{errors.email}</p>
						</div>

						<Form.Group className="mb-0 pointer" controlId="terms">
							<Form.Check
								type="checkbox"
								required
								onChange={handleChange}
								name="terms1"
								label={
									<>
										I accept the{" "}
										<span
											onClick={() => handleShow("term")}
											className="clr-green text-decoration-underline pointer">
											terms and conditions{" "}
										</span>
									</>
								}
							/>
						</Form.Group>

						<Form.Group className="mb-3 pointer" controlId="privacy">
							<Form.Check
								type="checkbox"
								required
								onChange={handleChange}
								name="terms2"
								label={
									<>
										I accept the{" "}
										<span
											onClick={() => handleShow("privacy")}
											className="clr-green text-decoration-underline pointer">
											data handling and privacy policy
										</span>
									</>
								}
							/>
						</Form.Group>

						{/* dynamic button  */}
						{active === false ? (
							<Button
								className="btn-icon"
								onClick={validate}
								disabled={
									values.terms1 && values.terms2 === true ? false : false
								}
								variant="primary"
								type="button">
								<img
									src={Metamaskicon}
									className="img-fluid"
									alt="Metamaskicon"
								/>{" "}
								CONNECT METAMASK
							</Button>
						) : (
							<Button
								className="btn-icon btn-disable"
								onClick={validate}
								disabled={
									values.name !== ""
										? values.terms1 === false || values.terms2 === false
											? true
											: false
										: false
								}
								variant="primary"
								type="submit">
								<img
									src={Metamaskicon}
									className="img-fluid"
									alt="Metamaskicon"
								/>{" "}
								Pay {pay} $istest for {counter} tonne(s)
								{/* <Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/> */}
							</Button>
						)}
					</Form>
				)}
			</Formik>
			{/* <a href={PDFFile} download>aa</a> */}

			<TermsPrivacy show={show} setShow={setShow} terms={terms} />
			<ExtensionPopup show1={show1} setShow1={setShow1} />
			<ConnectAccount show2={show2} setShow2={setShow2} />
		</>
	);
};

export default Forms;
