import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import Projectimg from "../../assets/images/projectimg.png";
import ApiContext from "../../store/Apis/ApiContext";

const ProjectDetails = () => {
	const { products } = useContext(ApiContext);
	return (
		<Col
			className="projectimage"
			style={{
				backgroundImage: `url(${
					process.env.REACT_APP_IMAGE_URL + products[0]?.imagepath
				})`,
			}}
			md={6}>
			<div className="projectdescc drop-shadow">
				<p className="fs-12 mb-2">Offsetting Projects</p>
				<h4 className="fs-20 fw-bold mb-2">{products[0]?.name}</h4>
				<p className="fs-12 fw-bold pricetag mb-3">
					{products[0]?.tokenPerTon} $istest/t
				</p>
				<p className="fs-12 wordbreak">{products[0]?.description}</p>
			</div>
		</Col>
	);
};

export default ProjectDetails;
