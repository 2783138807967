import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import WalletState from "./store/Wallet/WalletState";
import ApiState from "./store/Apis/ApiState";

function getLibrary(provider) {
  return new Web3(provider);
}
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletState>
        <ApiState>
          <App />
        </ApiState>
      </WalletState>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
