import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ExtensionPopup = ({ show2, setShow2 }) => {
  const handleClose = () => setShow2(false);
  return (
    <Modal show={show2} onHide={handleClose} centered scrollable size="sm">
      <Modal.Body>
        <p className="clr-grey text-center mb-0 py-2">Please, Connect with the MetaMask Account</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="fs-12 fw-bold bg-grey clr-grey btnsecondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtensionPopup;
