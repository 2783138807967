import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_APIPOINT;

axiosClient.defaults.headers = {
	"Content-Type": "application/json",
	Accept: "application/json",
};

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000;

axiosClient.interceptors.response.use(
	function (response) {
		// console.log("rr", response);
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		// console.log("rr", error.Error);
		return Promise.reject(error);
	}
);

export default axiosClient;
