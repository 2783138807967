import React, { useState, useContext } from "react";
import { Button, Col } from "react-bootstrap";
import Forms from "../Forms";
import PriceCalculations from "../../helpers/PriceCalculations";
import ApiContext from "../../store/Apis/ApiContext";
import WalletContext from "../../store/Wallet/WalletContext";
import successImg from "../../assets/images/success_offset.png";

const ProjectPrice = () => {
	const [counter, setCounter] = useState(1);
	const { products } = useContext(ApiContext);

	const { isTrxSuccessful, setIsTrxSuccessful, userEmail,setUserEmail } = useContext(WalletContext);
	
	

	const successScreenHandler = () => {
		setIsTrxSuccessful(false);
		setUserEmail("");
	};

	function PlusIcon() {
		return (
			<svg
				onClick={increment}
				width="27"
				height="28"
				viewBox="0 0 27 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13.4492 26.566C20.2934 26.566 25.8417 21.0177 25.8417 14.1735C25.8417 7.32933 20.2934 1.78101 13.4492 1.78101C6.60496 1.78101 1.05664 7.32933 1.05664 14.1735C1.05664 21.0177 6.60496 26.566 13.4492 26.566Z"
					stroke="black"
					strokeWidth="2.07627"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.4508 9.21643V19.1304"
					stroke="black"
					strokeWidth="2.07627"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.4939 14.1735H18.4079"
					stroke="black"
					strokeWidth="2.07627"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}
	function MinusIcon() {
		return (
			<svg
				onClick={decrement}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.3925 26.566C21.2367 26.566 26.785 21.0177 26.785 14.1735C26.785 7.32933 21.2367 1.78101 14.3925 1.78101C7.54832 1.78101 2 7.32933 2 14.1735C2 21.0177 7.54832 26.566 14.3925 26.566Z"
					stroke="black"
					strokeWidth="2.07627"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.43726 14.1733H19.3513"
					stroke="black"
					strokeWidth="2.07627"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}
	function increment() {
		setCounter(counter + 1);
	}
	function decrement() {
		setCounter(counter - 1);
	}
	if (counter <= 1) {
		decrement = () => setCounter(1);
	}
	return (
		<Col className="bg-white p-5" md={6}>
			<div className="projectprice">
				{!isTrxSuccessful && (
					<>
						<div className="d-flex align-items-start">
							<div className="flex-grow-1">
								<h4 className="mb-0 ">{products[0]?.title}</h4>
								<p className="fw-bold clr-green mt-1">
									{PriceCalculations(counter, products[0]?.tokenPerTon)} $istest
								</p>
							</div>
							<div className="counter d-flex align-items-center">
								<MinusIcon />
								<div className="text-center mt-3 w-60">
									<p className="mb-0 fs-20 fw-bold">{counter}</p>
									<span className="fs-12">tonne(s)</span>
								</div>
								<PlusIcon />
							</div>
						</div>
						<Forms
							pay={PriceCalculations(counter, products[0]?.tokenPerTon)}
							counter={counter}
						/>{" "}
					</>
				)}
				{/* Success Screen */}
				{isTrxSuccessful && (
					<>
						<div
							className="successImage mx-5"
							style={{ width: "100px", placeItems: "center" }}>
							<img className="w-100" src={successImg} alt="" />
						</div>
						<div className="success-screen">
							<h2>Congratulations</h2>
							<p>You have successfully offset {counter} tonne(s) of CO₂</p>
							<p>
								<strong>Certificate will be downloaded shortly!</strong>
							</p>
							{userEmail && <p>
								<strong>Email has been sent too!</strong>
							</p>}
							<Button
								className="btn-icon"
								onClick={successScreenHandler}
								variant="primary"
								type="button">
								{/* <img src={Metamaskicon} className="img-fluid" alt="Metamaskicon" />{" "} */}
								Offset More Tonnes!
							</Button>
						</div>
					</>
				)}
			</div>
		</Col>
	);
};

export default ProjectPrice;
