import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import ApiContext from "../../store/Apis/ApiContext";
import { createMarkup } from "../../helpers/react-html-editor";

const TermsPrivacy = ({ terms, show, setShow }) => {
	const handleClose = () => setShow(false);
	const { agreements } = useContext(ApiContext);
	return (
		<Modal show={show} onHide={handleClose} centered scrollable size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					{terms === true ? "Terms & Conditions" : "Privacy Policies"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{terms === true ? (
					<div
						className="preview"
						dangerouslySetInnerHTML={{
							__html: agreements[0]?.termNCondition,
						}}></div>
				) : (
					<div
						className="preview"
						dangerouslySetInnerHTML={createMarkup(
							agreements[0]?.privacy
						)}></div>
				)}
				{/* <p>
					{terms === true
						? createMarkup(agreements[0]?.termNCondition)
						: createMarkup(agreements[0]?.privacy)}
				</p> */}
			</Modal.Body>
		</Modal>
	);
};

export default TermsPrivacy;
