import React, { useContext, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../utils/Connectors";
import WalletContext from "./WalletContext";
import Web3 from "web3";
import contract from "../../contract/contract.json";
import download from "downloadjs";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import PDFFile from "../../assets/images/10mb.pdf";
import {
	getCertificateRequest,
	getRequest,
	postRequest,
} from "./../../utils/AxiosMethods";

import ApiContext from "../Apis/ApiContext";

const AppWallet = (props) => {
	const contractAddress = "0xDaA12f5C9E4E7838120D36145b18CE82f42418E8";
	const abi = contract;

	const [isTrxSuccessful, setIsTrxSuccessful] = useState(false);
	const [loader, setLoader] = useState(false);
	const [userEmail, setUserEmail] = useState();

	const { active, account, activate, deactivate } = useWeb3React();
	const web3 = new Web3(Web3.givenProvider);
	const contractInstance = new web3.eth.Contract(abi, contractAddress);

	const toastSuccess = (text) => toast.success(text);
	const toastInfo = (text) => toast.info(text);
	const toastError = (text) => toast.error(text);

	async function connect() {
		try {
			localStorage.setItem("metamask", "true");
			await activate(injected);
		} catch (ex) {
			// console.log(ex);
		}
	}

	async function disconnect() {
		localStorage.removeItem("metamask");
		try {
			deactivate();
		} catch (ex) {
			// console.log(ex);
		}
	}

	// Add product history to the database with trx_hash
	const createProductHistory = async (productPayload) => {
		try {
			const response = await postRequest("productshistory", productPayload, {
				responseType: "json",
			});
			// const blob = new Blob([response.data], {
			// 	type: "application/pdf",
			// });

			// let url = window.URL.createObjectURL(blob);
			let url = process.env.REACT_APP_IMAGE_URL + response.data.data.pdfPath;
			let a = document.createElement("a");
			a.setAttribute("href", url);
			a.setAttribute("download", "certificate.pdf");

			if (!/Mobi|Android/i.test(navigator.userAgent)) {
				// console.log("inside if", window.navigator.userAgentData.mobile);
				// console.log("inside if", !/Mobi|Android/i.test(navigator.userAgent));
				a.setAttribute("target", "_blank");
			}
			a.setAttribute("rel", "noreferrer");
			document.body.appendChild(a);
			a.click();
		} catch (error) {}
	};

	const sendTrx = async (data, productInfo, counter, timestamp) => {
		// console.log({ data, productInfo, counter, timestamp });

		try {
			setLoader(true);
			setUserEmail(data.email);
			await contractInstance.methods
				.transfer(
					contractAddress,
					web3.utils.toWei((productInfo[0].tokenPerTon * counter).toString())
				)
				.send({ from: account })
				.on("receipt", function (receipt) {
					// console.log({ receipt });
					// console.log(receipt.transactionHash, receipt.status);
					if (receipt.status) {
						const payload = {
							username: data.name,
							description: productInfo[0]?.description,
							unitPurchased: counter,
							tokenPerUnit: productInfo[0]?.tokenPerTon,
							email: data.email,
							trx_id: receipt.transactionHash,
							total_tokens: counter,
							product_name: productInfo[0]?.title,
							timedate: timestamp,
						};
						setLoader(false);
						setIsTrxSuccessful(true);
						createProductHistory(payload);
						toastSuccess("Transaction Successful");
						setTimeout(() => {
							toastInfo("Certificate will be downloaded shortly");
						}, 1500);
					}
				});
			// .on("error", function (error) {
			// 	console.log("apple", error.receipt, error.status);
			// 	const errorMessage = error?.erorr?.message?.split("EVM")[0];
			// 	toastError(errorMessage);
			// });
		} catch (error) {
			// console.log(error.receipt.status);
			// console.log({ error });
			// console.log(error.receipt);
			// console.log(error.message.split("EVM")[0]);
			if (error.code === 4001) {
				setLoader(false);
				toastError(error.message);
			}
			if (error?.receipt?.status === false) {
				setLoader(false);
				toastError("Transaction has been reverted by the EVM");
			}
		}
	};

	const addProductHistory = async (params) => {};

	return (
		<WalletContext.Provider
			value={{
				connect,
				disconnect,
				sendTrx,
				setUserEmail,
				setIsTrxSuccessful,
				isTrxSuccessful: isTrxSuccessful,
				loader: loader,
				active,
				account,
				web3: web3,
				userEmail: userEmail,
			}}>
			{props.children}
		</WalletContext.Provider>
	);
};

export default AppWallet;
