import axiosClient from "./AxiosClient";

export function getRequest(URL) {
	return axiosClient.get(`/${URL}`).then((response) => response);
}

export function getCertificateRequest(URL, payload) {
	return axiosClient.get(`/${URL}`, payload).then((response) => response);
}

export function postRequest(URL, payload, responseType) {
	return axiosClient
		.post(`/${URL}`, payload, responseType)
		.then((response) => response);
}

export function patchRequest(URL, payload) {
	return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
	return axiosClient.delete(`/${URL}`).then((response) => response);
}
