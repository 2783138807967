import React, { useReducer } from "react";
import ApiReducer from "./ApiReducer";
import ApiContext from "./ApiContext";
import { getRequest, postRequest } from "../../utils/AxiosMethods";

const initialState = {
	agreements: [],
	products: [],
};

const ApiState = (props) => {
	const [state, dispatch] = useReducer(ApiReducer, initialState);

	async function getAgreements() {
		try {
			const resp = await getRequest("agreements");
			if (resp.status === 200) {
				dispatch({
					type: "GET_AGREEMENTS",
					payload: resp.data,
				});
			} else {
				// console.log(resp);
			}
		} catch (error) {
			// console.log(error);
		}
	}

	async function getProducts() {
		try {
			const resp = await getRequest("products");
			if (resp.status === 200) {
				dispatch({
					type: "GET_PRODUCTS",
					payload: resp.data,
				});
			} else {
				// console.log(resp);
			}
		} catch (error) {
			// console.log(error);
		}
	}

	return (
		<ApiContext.Provider
			value={{
				getAgreements,
				agreements: state.agreements,
				getProducts,
				products: state.products,
				// createProductHistory,
			}}>
			{props.children}
		</ApiContext.Provider>
	);
};

export default ApiState;
