import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/screen.css";
import Home from "./components/Home";
import WalletContext from "./store/Wallet/WalletContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const metamaskactive = localStorage.getItem("metamask");

const App = () => {
	const { connect, disconnect } = useContext(WalletContext);
	useEffect(() => {
		if (metamaskactive === "true") {
			connect();
		} else {
			localStorage.removeItem("metamask"); 
			disconnect();
		}
	}, []);
	return (
		<>
			<Router>
				<Switch>
					<Route path="/" component={Home} />
				</Switch>
			</Router>
			<ToastContainer />
		</>
	);
};

export default App;
