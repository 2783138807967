import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ExtensionPopup = ({ show1, setShow1 }) => {
  const handleClose = () => setShow1(false);
  return (
    <Modal show={show1} onHide={handleClose} centered scrollable size="md">
      <Modal.Body>
        <p className="clr-grey text-center mb-0 py-2">
          “Please install metamask extension, if you're using the mobile browser
          app, you can proceed on metamask mobile app by copy the impact scope
          website URI in metamask browser mode and offset carbon CO2”
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="fs-12 fw-bold bg-grey clr-grey btnsecondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          className="fs-12 fw-bold"
          href="https://metamask.io/download"
          target="_blank"
        >
          Install Extension
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtensionPopup;
