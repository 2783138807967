import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Name must be atleast 3 characters long")
		.max(20, "Name must be less than 20 characters"),
	email: Yup.string().email("Vaild Email Required*"),
	terms1: Yup.bool().required(),
	terms2: Yup.bool().required(),
});
