import React, { useEffect, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import Header from "../Header";
import ProjectOffsets from "../Project/ProjectOffsets";
import ProjectDetails from "../Project/ProjectDetails";
import ProjectPrice from "../Project/ProjectPrice";
import ApiContext from "../../store/Apis/ApiContext";
import LoadingOverlay from "react-loading-overlay";
import WalletContext from "../../store/Wallet/WalletContext";

const Home = () => {
	const { getAgreements, getProducts } = useContext(ApiContext);
	const { loader } = useContext(WalletContext);
	useEffect(() => {
		getAgreements();
		getProducts();
	}, []);
	return (
		<>
			<LoadingOverlay active={loader} spinner text="Please wait, your transaction is processing">
				<Header />
				<section className="project pb-60">
					<Container>
						<ProjectOffsets />
						<Row className="g-0 projectwrapper">
							<ProjectDetails />
							<ProjectPrice />
						</Row>
					</Container>
				</section>
			</LoadingOverlay>
		</>
	);
};

export default Home;
