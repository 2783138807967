import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ApiContext from "../../store/Apis/ApiContext";
import { createMarkup } from "../../helpers/react-html-editor";

const ProjectOffsets = () => {
	const { agreements } = useContext(ApiContext);
	return (
		<Row>
			<Col xs={12}>
				<div className="text-center mt-40">
					{/* <h2 className="fs-28 mb-4">{agreements[0]?.offsetScreenText}</h2> */}
					<h2
						className="fs-28 mb-4 preview"
						dangerouslySetInnerHTML={createMarkup(
							agreements[0]?.offsetScreenText
						)}></h2>
				</div>
			</Col>
		</Row>
	);
};

export default ProjectOffsets;
