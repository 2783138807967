import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import Verra from "../../assets/images/verra.svg";

const Header = () => {
  return (
    <section className="headerarea pt-40">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="text-center">
              <img src={Logo} className="logo img-fluid" alt="logo" />
              <h1>Great! You are about to reduce your carbon footprint</h1>
              <p>
                All the carbon removed or avoided by ImpactScope partners is
                verified by Verra, the world's leading carbon offset
                certification body.
              </p>
              <img src={Verra} className="img-fluid" alt="Verra" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
